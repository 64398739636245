import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Screens/Footer';
import Home from './Screens/Home';
import About from './Screens/About';
import Contact from './Screens/Contact';
import Navbar from './Screens/Navbar';
import Privacypolicy from './Screens/Privacypolicy';
import Terms from './Screens/Terms';
import Login from './Screens/Drivermanagement/Login';
import Contactus from './Screens/Contactus';
import Chatsupport from './Screens/CustomerSupport/Chatsupport';
import UserChat from './Screens/CustomerSupport/UserChat';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
             <Route path="/contactus" element={<Contactus />} />
             <Route path="/PrivacyPolicy" element={<Privacypolicy />} />
              <Route path="/Terms&Conditions" element={<Terms />} />
              <Route path="/Driver" element={<Login />} />
               <Route path="/Support" element={<Chatsupport />} />
               <Route path="/Supportuser" element={<UserChat />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
