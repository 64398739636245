import React, { useState } from 'react';
import axios from 'axios';
import './DriverDashboard.css';

export default function DriverDashboard() {
  const [searchByNumber, setSearchByNumber] = useState(false);
  const [searchByAccount, setSearchByAccount] = useState(false);
  const [mobile, setMobile] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
const [driverData, setDriverData] = useState();
  const [isModified, setIsModified] = useState(false); // Tracks if any changes are made
  const [editdata,setEditdata]=useState()

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDriverData({
      ...driverData,
      [name]: value,
    });
    setEditdata({
      ...editdata,
      [name]: value,
    });
    setIsModified(true); // Set isModified to true when any field changes
  };

  // Save changes (this can be hooked into an API call)
  const handleSave = (id) => {
    
axios.post('http://192.168.1.13:4003/api/auth/editdriveraccount', {editdata,id})
    .then(response => {
      console.log(response.data)
        if (response.data.affectedRows === 1) {
          alert("saved")
          setDriverData()
        } else {
          alert("Something went wrong tryagain ")
        }
      })
      .catch(error => {
        console.log('Error fetching driver data:', error);
      });
    console.log('Saving driver data', editdata);
    setIsModified(false); // Reset the modification state after saving
  };
  const handleSearchByMobile = (mobile) => {
    axios.post('http://192.168.1.13:4003/api/auth/getdriverbymobile', { mobile })
      .then(response => {
        if (response.data.length > 0) {
          setDrivers(response.data);
          setDriverData({...response.data[0]});
        } else {
          setDrivers([]);
          setSelectedDriver(null);
        }
      })
      .catch(error => {
        console.error('Error fetching driver data:', error);
      });
  };
  const handleSearchByaccount = () => {
    axios.post('http://192.168.1.13:4003/api/auth/getdriverbyaccount', {accountStatus})
      .then(response => {
        if (response.data.length > 0) {
          setDrivers(response.data);
          console.log(response.data)
        } else {
          setDrivers([]);
          setSelectedDriver(null);
        }
      })
      .catch(error => {
        console.error('Error fetching driver data:', error);
      });
  };
  return (
    <div>
    <div className="dashboard-container">
      <div className="search-bar-section">
        <p className={`search-button ${searchByNumber ? 'active' : ''}`} onClick={() => [setSearchByNumber(!searchByNumber), setSearchByAccount(false)]}>
          Search by number
        </p>
        <p className={`search-button ${searchByAccount ? 'active' : ''}`} onClick={() => [setSearchByAccount(!searchByAccount), setSearchByNumber(false)]}>
          Search by account status
        </p>
      </div>

      {searchByNumber && (
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search by mobile number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className="input-field"
          />
          <button onClick={() => handleSearchByMobile(mobile)} className="search-button">Search</button>
        </div>
      )}

      {searchByAccount && (
        <div className="form">
          <label>Account Status:</label>
          <select value={accountStatus} onChange={(e) => setAccountStatus(e.target.value)} className="select-field">
            <option value="">All</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="suspended">Suspended</option>
          </select>
          <p className='search-button1' onClick={() => handleSearchByaccount()}>Search</p>
        </div>
      )}

      { mobile && driverData&&(
         <div className="profile-container">
      <h2>Driver Profile</h2>

      {/* Name input field */}
      <div className="form-group">
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={driverData.name}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label>Date of Birth (dd/mm/yy):</label>
        <input
          type="text"
          name="DOB"
          value={(driverData.DOB)}  // Display the date in dd/mm/yy format
          onChange={handleInputChange}             // Handle change and format the date
          className="form-control"
          placeholder="dd/mm/yy"
        />
      </div>
      {/* Email input field */}
      <div className="form-group">
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={driverData.email}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
       <div className="form-group">
        <label>Adhar No::</label>
        <input
          type="text"
          name="adharno"
          value={driverData.adharno}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
       <div className="form-group">
        <label>Driving Lisence NO::</label>
        <input
          type="text"
          name="drivinglisence"
          value={driverData.drivinglisence}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      {/* Gender dropdown */}
      <div className="form-group">
        <label>Gender:</label>
        <select
          name="gender"
          value={driverData.gender}
          onChange={handleInputChange}
          className="form-control"
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      </div>

      {/* Account Status dropdown */}
      <div className="form-group">
        <label>Account Status:</label>
        <select
          name="accountStatus"
          value={driverData.accountStatus}
          onChange={handleInputChange}
          className="form-control"
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="suspended">Suspended</option>
        </select>
      </div>

      {/* Show the Save button only if something is modified */}
      {isModified && <button className="save-button" onClick={()=>handleSave(driverData.id)}>Save</button>}
    </div>
        )}
        
      


      {searchByAccount &&(
        <>
        <h3 style={{margin:"20px"}}>Result</h3>
        {drivers.map((val)=>{return(
          <div className="driverdetails" onClick={()=>[setSelectedDriver(val),setDriverData({...val})]} style={{backgroundColor:selectedDriver && selectedDriver.id === val.id?"rgb(200, 200, 200)":""}}>
          <p>Name: <strong>{val.name}</strong></p>
          <p>Mobile: <strong>{val.mobile}</strong></p>
          {/* Add other fields as needed */}
        </div>
        )})}
        {driverData&&(
         <div className="profile-container">
      <h2>Driver Profile</h2>

      {/* Name input field */}
      <div className="form-group">
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={driverData.name}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label>Date of Birth (dd/mm/yy):</label>
        <input
          type="text"
          name="DOB"
          value={(driverData.DOB)}  // Display the date in dd/mm/yy format
          onChange={handleInputChange}             // Handle change and format the date
          className="form-control"
          placeholder="dd/mm/yy"
        />
      </div>
      {/* Email input field */}
      <div className="form-group">
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={driverData.email}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
       <div className="form-group">
        <label>Adhar No::</label>
        <input
          type="email"
          name="email"
          value={driverData.adharno}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
       <div className="form-group">
        <label>Driving Lisence NO::</label>
        <input
          type="email"
          name="email"
          value={driverData.drivinglisence}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      {/* Gender dropdown */}
      <div className="form-group">
        <label>Gender:</label>
        <select
          name="gender"
          value={driverData.gender}
          onChange={handleInputChange}
          className="form-control"
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      </div>

      {/* Account Status dropdown */}
      <div className="form-group">
        <label>Account Status:</label>
        <select
          name="accountStatus"
          value={driverData.accountStatus}
          onChange={handleInputChange}
          className="form-control"
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="suspended">Suspended</option>
        </select>
      </div>

      {/* Show the Save button only if something is modified */}
      {isModified && <button className="save-button" onClick={handleSave}>Save</button>}
    </div>
        )}
        
        </>

      )}
      
    </div>
    </div>
  );
}
