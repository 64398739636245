import axios from 'axios';
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const socket = io('http://192.168.1.13:4004');

function Chatsopport() {
    const [activeChats, setActiveChats] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);

    useEffect(() => {
        socket.emit('admin_join');

        // Load all active chats
        socket.on('active_chats', (chats) => {
            setActiveChats(chats);
        });

        // Load specific chat history
        socket.on('receive_message', (messageData) => {
            setChatHistory((prevHistory) => [...prevHistory, messageData]);
        });
    }, [ ]);

    const selectChat = async (chatRoomId) => {
        setCurrentChat(chatRoomId);

        // Fetch chat history for the selected chat room
        const response = await axios.get(`http://l192.168.1.13:4004/history`);
        setChatHistory(response.data);
    };

    const sendMessage = () => {
        const newMessage = { chatRoomId: currentChat, adminId: 1, message, userId: currentChat.user_id };
        socket.emit('admin_send_message', newMessage);
        setChatHistory([...chatHistory, { sender_id: 1, content: message }]);
        setMessage('');
    };

    return (
        <div>
            <h1>Admin Panel</h1>
            <div>
                <h2>Active Chats</h2>
                <ul>
                    {activeChats.map((chat) => (
                        <li key={chat.id} onClick={() => selectChat(chat.id)}>
                            User {chat.user_id}
                        </li>
                    ))}
                </ul>
            </div>

            {currentChat && (
                <div>
                    <h2>Chat History</h2>
                    <div className="chat-window">
                        {chatHistory.map((msg, index) => (
                            <div key={index}>
                                <b>{msg.sender_id === 1 ? 'Admin' : 'User'}:</b> {msg.content}
                            </div>
                        ))}
                    </div>
                    <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <button onClick={()=>sendMessage}>Send</button>
                </div>
            )}
        </div>
    );
}

export default Chatsopport;
