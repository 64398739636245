import React from 'react';
import './About.css';
import sunanthImage from '../assets/sunanth.png';
import rushikanthImage from '../assets/rushi1.jpg';
import { FaCheckCircle } from 'react-icons/fa';
import icon from '../assets/icon.png'
export default function AboutUs  ()  {
  return (
    <div className="about-us-container">
      {/* Company Description Section */}
      <section className="company-section">
        <h2>About Us</h2>
        <div className='aboutsection'>
        <img src={icon} alt="icon" />
        <div className='p'>
        <p>
          At <span className="highlight">Shawave Technologies</span>, we are dedicated to developing innovative solutions that tackle everyday challenges. Our mission is to create products that enhance security, efficiency, and convenience for our clients. From cutting-edge technology to user-friendly applications, we strive to make a meaningful impact on people's lives.
        </p>
         <div className="features-list">
        
          <h3 className='features-list1'><FaCheckCircle className="icon" /> Innovative Technologies</h3>
          <h3 className='features-list1'><FaCheckCircle className="icon" /> Customer-Centric Approach</h3>
          <h3 className='features-list1'><FaCheckCircle className="icon" /> Reliable Support</h3>
        </div>
        </div>
        </div>
       
       
      </section>

      {/* Founders Section */}
       <section className="founders-section">
          <h2>Our Founders</h2>
          <div className="founders">
            {/* Founder 1 */}
            <div className="founder-card">
              <div className="founder-image-container">
                <img src={sunanthImage} alt="Sunanth" className="founder-image" />
              </div>
              <h3>Sunanth Samala</h3>
              <p className="founder-title">Founder</p>
              <p className="founder-description">
                Sunanth Samala is the founder of Driver Spot and a skilled software developer with a background in Computer Science and Engineering.
                Specializing in mobile and web applications, Sunanth combines technical expertise with an entrepreneurial mindset to deliver innovative solutions in the mobility industry.
                With a focus on user-centric design and scalable technology, he leads DriverSpot in transforming transportation services.
              </p>
            </div>

            {/* Founder 2 */}
            <div className="founder-card">
              <div className="founder-image-container">
                <img src={rushikanthImage} alt="Rushikanth" className="founder-image" />
              </div>
              <h3>Rushikanth</h3>
              <p className="founder-title">Co-Founder</p>
              <p className="founder-description">
                Rushikanth, a Computer Science graduate with two years of experience at TIS, brings a strong technical background and business acumen to DriverSpot.
                As the co-founder, he oversees the business operations, driving strategic growth and ensuring smooth execution across the company.
                His blend of industry knowledge and operational insight positions him as a key leader in scaling the company and optimizing its impact in the mobility sector.
              </p>
            </div>
          </div>
        </section>
    </div>
  );
};


