import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import axios from 'axios';

const socket = io('http://192.168.1.13:4004');

function UserChat() {
    const [userId, setUserId] = useState("2");
    const [message, setMessage] = useState('');
    const [chat, setChat] = useState([]);
    const [chatRoomId, setChatRoomId] = useState(null);

    useEffect(() => {
       

        // Load chat history when joining
        socket.on('chat_history', (messages) => {
            setChat(messages);
        });

        // Receive new message
        socket.on('receive_message', (messageData) => {
            setChat((prevChat) => [...prevChat, messageData]);
        });
    }, [ ]);

    const sendMessage = () => {
        const newMessage = { chatRoomId, senderId: userId, message };
        socket.emit('send_message', newMessage);
        setChat([...chat, { chatRoomId, senderId: userId, content: message }]);
        setMessage('');
    };

    return (
        <div>
            <h1>User Chat Room</h1>
            <div className="chat-window">
                {chat.map((msg, index) => (
                    <div key={index}>
                        <b>{msg.sender_id === userId ? 'You' : 'Admin'}:</b> {msg.content}
                    </div>
                ))}
            </div>
            <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <button onClick={()=>sendMessage}>Send</button>
        </div>
    );
}

export default UserChat;
